<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"
      rel="stylesheet"
  />

  <div class="grid grid-cols-12">
    <div class="col-span-6">
      <h1 id="headline" class="default-text text-3xl">GABRIELE SCHLESSELMANN</h1>
    </div>
    <div class="col-start-12 flex flex-col mt-3 mb-1 justify-start">
      <NavIcon class="z-50" is-mobile />
      <div id="mobile-nav-items" class="flex flex-row">
        <div id="mobile-menu-background-fade" />
        <div class="gray-text flex flex-col justify-start">
          <router-link class="pl-4 grey-text" to="/einzelwerke/">Einzelwerke</router-link>
        </div>
        <div>
          <router-link class="pl-4 grey-text" to="/serien-uebersicht/"
            >Serien</router-link
          >
        </div>
        <div><router-link class="pl-4 grey-text" to="/vita/">Vita</router-link></div>
        <div>
          <router-link class="pl-4 grey-text" to="/ausstellungen/"
            >Ausstellungen</router-link
          >
        </div>
        <div>
          <router-link class="pl-4 grey-text" to="/impressum/">Impressum</router-link>
        </div>
      </div>
    </div>
    <hr class="mt-10 col-span-12" />
  </div>
</template>

<script>
import NavIcon from "@/components/Navbar/NavIcon.vue";

export default {
  name: "MobileMenu",
  components: {
    NavIcon,
  },
  watch: {
    $route() {
      this.closeMenu();
    }
  },
  methods: {
    closeMenu() {
      const navIcon = document.getElementById("nav-icon");
      const mobileNavItems = document.getElementById("mobile-nav-items");
      
      if (navIcon) navIcon.classList.remove("open");
      if (mobileNavItems) mobileNavItems.classList.remove("open");
    }
  }
};
</script>

<style scoped>
#headline {
  width: 120px;
  letter-spacing: 2.4px;
  line-height: 54px;
  font-family: "Open Sans", sans-serif;
  font-weight: lighter;
}

#mobile-nav-items {
  width: 10rem;
  opacity: 0;
  transition: 0.25s ease-in-out;
  display: None;
  position: absolute;
  right: 0;
  top: 4rem;
  z-index: 10;
  height: 100%;
}

#mobile-nav-items.open {
  opacity: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.95), rgb(255, 255, 255, 1));
  margin-right: 0;
  transition: 0.25s ease-in-out;
  display: inline !important;
}

#mobile-nav-items div {
  width: 20rem;
  left: 2rem;
  font-size: 18px;
  letter-spacing: 0.36px;
  line-height: 48px;
  margin-top: 1rem;
}

#mobile-menu-background-fade {
  width: 2rem !important;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255, 0.95));
  position: absolute;
  left: -1.99rem !important;
}

hr {
  color: #00000080;
  background-color: #00000080;
  height: 1px;
  border: 0;
  margin-top: 60px;
}
</style>
