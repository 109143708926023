<template>
  <section>
    <div class="w-full h-[80vh] md:h-screen mt-20 flex">
      <div class="w-2/3 h-3/4 m-auto flex flex-col justify-center">
        <div class="mx-auto max-w-full max-h-full my-auto" style="width: max-content">
          <img
            class="max-w-full max-h-full w-auto h-auto object-contain cursor-pointer md:cursor-default"
            :src="pictureData.imgFull"
            :alt="pictureData.title"
            @click="handleImageClick"
          />
          <p class="description-text title-text mt-7">{{ pictureData.title }}</p>
          <p class="description-text">
            {{ pictureData.year }}, {{ pictureData.technique }},
            {{ pictureData.height }} x {{ pictureData.width }} cm
          </p>
        </div>
      </div>
    </div>
    <!-- Mobile Lightbox -->
    <div v-if="showLightbox" 
         class="fixed inset-0 bg-black bg-opacity-90 z-50 md:hidden overflow-hidden"
         @click="closeLightbox">
      <div class="w-full h-full flex items-center justify-center px-4">
        <div class="w-full max-w-full">
          <img
            :src="pictureData.imgFull"
            :alt="pictureData.title"
            class="max-w-full w-full h-auto object-contain"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImageView",
  data: function () {
    const pictureData = this.rawPictureData;
    pictureData.imgFull = require(`@/assets/img/${pictureData.imgFull}`);
    return {
      containerHeight: window.innerHeight * 0.8,
      pictureData: pictureData,
      showLightbox: false,
    };
  },
  props: {
    rawPictureData: Object,
  },
  methods: {
    handleImageClick() {
      // Only show lightbox on mobile
      if (window.innerWidth < 768) {
        this.showLightbox = true;
        document.body.style.overflow = 'hidden';
      }
    },
    closeLightbox() {
      this.showLightbox = false;
      document.body.style.overflow = 'auto';
    }
  },
  beforeUnmount() {
    // Ensure body scroll is restored when component is destroyed
    document.body.style.overflow = 'auto';
  }
};
</script>

<style scoped>
.title-text {
  font-weight: bold !important;
}

.description-text {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  color: #00000080;
}

/*section {*/
/*  scroll-snap-align: center;*/
/*}*/
</style>
