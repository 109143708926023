import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueEasyLightbox from "vue-easy-lightbox";
import "./assets/tailwind.css";
import "./assets/index.css";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import './styles/lightbox.css';

library.add(faAngleUp);

createApp(App)
  .use(store)
  .use(router)
  .use(VueEasyLightbox)
  .component("fa-angle-up", FontAwesomeIcon)
  .mount("#app");
