<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"
    rel="stylesheet"
  />

  <div id="navbar">
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <h1 id="headline" class="default-text lg:text-5xl text-3xl">GABRIELE SCHLESSELMANN</h1>
      </div>
<!--      <div v-if="innerWidth > mobileBreakpointWidth">-->
        <div class="col-span-5 flex items-end justify-end">
          <div id="nav-items" class="grey-text">
            <div>
              <router-link class="pl-4" to="/einzelwerke/"
                >Einzelwerke</router-link
              >
            </div>
            <div>
              <router-link class="pl-4" to="/serien-uebersicht/"
              >Serien</router-link
              >
            </div>
            <div><router-link class="pl-4" to="/vita/">Vita</router-link></div>
            <div>
              <router-link class="pl-4" to="/ausstellungen/"
                >Ausstellungen</router-link
              >
            </div>
            <div>
              <router-link class="pl-4" to="/impressum/">Impressum</router-link>
            </div>
          </div>
        </div>
<!--      </div>-->
      <div class="col-start-12 flex lg:items-end lg:mt-0 mt-3 mb-1 justify-end">
        <NavIcon />
      </div>
    </div>
    <hr class="mt-12" />
  </div>
</template>

<script>
import NavIcon from "@/components/Navbar/NavIcon.vue";
import { useInnerWidth } from "@/util";
import { mobileBreakpointWidth } from "@/conf";
export default {
  name: "Navbar",
  components: {
    NavIcon,
  },
  data: function () {
    return {
      innerWidth: useInnerWidth(),
      mobileBreakpointWidth: mobileBreakpointWidth,
    };
  },
};
</script>

<style scoped>
#headline {
  width: 120px;
  letter-spacing: 2.4px;
  line-height: 54px;
}

#nav-items {
  opacity: 0;
  margin-right: -6em;
  transition: 0.25s ease-in-out;
}

#nav-items.open {
  opacity: 1;
  margin-right: 0;
  transition: 0.25s ease-in-out;
}

#nav-items div {
  display: inline;
  font-size: 18px;
  letter-spacing: 0.36px;
  line-height: 48px;
}

#navbar hr {
  color: #00000080;
  background-color: #00000080;
  height: 1px;
  border: 0;
  margin-top: 60px;
}
</style>
